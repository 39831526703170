import { MutableRefObject, useEffect } from 'react';

interface UseClickListenerOnPillDropdownParams {
	closeDropdown: () => void;
	containerRef: MutableRefObject<HTMLDivElement | null>;
	dropdownOpen: boolean;
	isMobile: boolean;
}

const useClickListenerOnPillDropdown = ({
	closeDropdown,
	containerRef,
	dropdownOpen,
	isMobile,
}: UseClickListenerOnPillDropdownParams) => {
	const clickListener = (e: MouseEvent) => {
		if (
			!isMobile &&
			!containerRef.current?.contains(e.target as Node) &&
			dropdownOpen
		) {
			closeDropdown();
		}
	};
	useEffect(() => {
		window.addEventListener('click', clickListener);
		return () => {
			window.removeEventListener('click', clickListener);
		};
	}, [containerRef, dropdownOpen]);
};

export default useClickListenerOnPillDropdown;
