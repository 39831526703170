import { useOutsideClickEffect } from '@xo-union/ui-accessibility';
import React, { forwardRef, useRef } from 'react';
import Tooltip from '../components/Tooltip';
import type { BaseTooltipProps } from '../types';

const Mobile = forwardRef<HTMLDivElement, BaseTooltipProps>((props, ref) => {
	const { setTooltipOpen, tooltipOpen } = props;
	const tooltipRef = useRef<HTMLSpanElement>(null);

	const closeMobileTooltip = (e: React.MouseEvent) => {
		if (tooltipOpen) {
			e.preventDefault();
			e.stopPropagation();
			setTooltipOpen(false);
		}
	};
	useOutsideClickEffect({ onClickOutside: closeMobileTooltip }, tooltipRef);

	return (
		<span ref={tooltipRef}>
			<Tooltip
				isDesktop={false}
				ref={ref}
				setTooltipOpen={setTooltipOpen}
				tooltipOpen={tooltipOpen}
			/>
		</span>
	);
});

export default Mobile;
