export const locationToString = <T extends Union.Location>(
	location: T | null,
) => {
	if (!location) {
		return '';
	}

	const commaSpace = location.city && location.stateCode ? ', ' : '';

	return `${location.city}${commaSpace}${location.stateCode}`;
};
