export type Bounds = {
	north: number;
	east: number;
	south: number;
	west: number;
};

type UsStateBounds = {
	[key: string]: Bounds;
};

export const usStateBounds: UsStateBounds = {
	AK: {
		north: 71.5388,
		south: 51.2097,
		east: -130.0148,
		west: -179.14891,
	},
	AL: {
		north: 35.008028,
		south: 30.144425,
		east: -84.888247,
		west: -88.473227,
	},
	AR: {
		north: 36.4996,
		south: 33.004106,
		east: -89.644395,
		west: -94.617919,
	},
	AZ: {
		north: 37.00426,
		south: 31.332177,
		east: -109.045223,
		west: -114.816591,
	},
	CA: {
		north: 42.009518,
		south: 32.529521,
		east: -114.131211,
		west: -124.409591,
	},
	CO: {
		north: 41.003444,
		south: 36.993076,
		east: -102.041524,
		west: -109.045223,
	},
	CT: {
		north: 42.050587,
		south: 40.9875,
		east: -71.787004,
		west: -73.727775,
	},
	DC: {
		north: 38.995548,
		south: 38.791645,
		east: -76.909393,
		west: -77.119759,
	},
	DE: {
		north: 39.839007,
		south: 38.451013,
		east: -75.048939,
		west: -75.789002,
	},
	FL: {
		north: 31.000809,
		south: 24.523096,
		east: -80.031362,
		west: -87.634643,
	},
	GA: {
		north: 35.000659,
		south: 30.355644,
		east: -80.751429,
		west: -85.605165,
	},
	HI: {
		north: 28.402123,
		south: 18.910361,
		east: -154.806773,
		west: -178.334698,
	},
	IA: {
		north: 43.501196,
		south: 40.375501,
		east: -90.140061,
		west: -96.639704,
	},
	ID: {
		north: 49.001146,
		south: 41.988057,
		east: -111.043564,
		west: -117.243027,
	},
	IL: {
		north: 42.508337,
		south: 36.970298,
		east: -87.495199,
		west: -91.513079,
	},
	IN: {
		north: 41.760592,
		south: 37.771742,
		east: -84.784579,
		west: -88.09776,
	},
	KS: {
		north: 40.003162,
		south: 36.993076,
		east: -94.588413,
		west: -102.051744,
	},
	KY: {
		north: 39.147359,
		south: 36.497129,
		east: -81.964971,
		west: -89.571509,
	},
	LA: {
		north: 33.019543,
		south: 28.928609,
		east: -88.758388,
		west: -94.043557,
	},
	MA: {
		north: 42.886589,
		south: 41.237964,
		east: -69.858861,
		west: -73.508142,
	},
	MD: {
		north: 39.723043,
		south: 37.88514,
		east: -74.984975,
		west: -79.487651,
	},
	ME: {
		north: 47.459686,
		south: 42.977764,
		east: -66.93457,
		west: -71.083924,
	},
	MI: {
		north: 48.306388,
		south: 41.696118,
		east: -82.122629,
		west: -90.418136,
	},
	MN: {
		north: 49.384358,
		south: 43.499356,
		east: -89.489226,
		west: -97.239209,
	},
	MO: {
		north: 40.61364,
		south: 35.995683,
		east: -89.098843,
		west: -95.774704,
	},
	MS: {
		north: 34.996052,
		south: 30.173943,
		east: -88.097888,
		west: -91.655009,
	},
	MT: {
		north: 49.00139,
		south: 44.358221,
		east: -104.039648,
		west: -116.049866,
	},
	NC: {
		north: 36.588117,
		south: 33.752878,
		east: -75.460621,
		west: -84.321869,
	},
	ND: {
		north: 49.000574,
		south: 45.935072,
		east: -96.554507,
		west: -104.0489,
	},
	NE: {
		north: 43.001708,
		south: 39.999998,
		east: -95.30829,
		west: -104.053514,
	},
	NH: {
		north: 45.305476,
		south: 42.697042,
		east: -70.603806,
		west: -72.557247,
	},
	NJ: {
		north: 41.357423,
		south: 38.928519,
		east: -73.88506,
		west: -75.563587,
	},
	NM: {
		north: 37.000232,
		south: 31.332301,
		east: -103.002136,
		west: -109.050173,
	},
	NV: {
		north: 42.002207,
		south: 35.001857,
		east: -114.039648,
		west: -120.005746,
	},
	NY: {
		north: 45.01585,
		south: 40.477399,
		east: -71.185082,
		west: -79.76259,
	},
	OH: {
		north: 42.327132,
		south: 38.403202,
		east: -80.518693,
		west: -84.820159,
	},
	OK: {
		north: 37.002206,
		south: 33.615833,
		east: -94.431978,
		west: -103.002084,
	},
	OR: {
		north: 46.292035,
		south: 41.991056,
		east: -116.46326,
		west: -124.566244,
	},
	PA: {
		north: 42.26986,
		south: 39.7198,
		east: -74.689517,
		west: -80.519851,
	},
	RI: {
		north: 42.018798,
		south: 41.146339,
		east: -71.12057,
		west: -71.862772,
	},
	SC: {
		north: 35.215402,
		south: 32.033454,
		east: -78.54203,
		west: -83.35391,
	},
	SD: {
		north: 45.94545,
		south: 42.479635,
		east: -96.436589,
		west: -104.057698,
	},
	TN: {
		north: 36.678118,
		south: 34.982924,
		east: -81.6469,
		west: -90.310298,
	},
	TX: {
		north: 36.500704,
		south: 25.837164,
		east: -93.508292,
		west: -106.645646,
	},
	UT: {
		north: 42.001567,
		south: 36.997968,
		east: -109.041573,
		west: -114.041028,
	},
	VA: {
		north: 39.466012,
		south: 36.540738,
		east: -75.242266,
		west: -83.67529,
	},
	VT: {
		north: 45.016659,
		south: 42.726853,
		east: -71.465,
		west: -73.437742,
	},
	WA: {
		north: 49.002494,
		south: 45.543541,
		east: -116.916503,
		west: -124.848974,
	},
	WI: {
		north: 47.080621,
		south: 42.49172,
		east: -86.249548,
		west: -92.888114,
	},
	WV: {
		north: 40.638801,
		south: 37.201483,
		east: -77.719519,
		west: -82.644739,
	},
	WY: {
		north: 45.005904,
		south: 40.994772,
		east: -104.05216,
		west: -111.056888,
	},
};
