import { compose as composeCSS } from '@xo-union/react-css-modules';
import { Column, Row } from '@xo-union/tk-component-grid';
import type { ColumnProps } from '@xo-union/tk-component-grid';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import RightRail from '../RightRail';
import Loading from './loading';
import Styles from './styles.scss';
import View from './view';

interface GridViewProps {
	isFetching: boolean;
	isDesktop: boolean;
	maxResultColumns: number;
}

const GridView: VFC<GridViewProps> = (props) => {
	const { isFetching, isDesktop, maxResultColumns } = props;
	const columnSizes: ColumnProps =
		maxResultColumns === 4
			? { xs: '12' }
			: { xs: '12', lg: '7', xl: '8', xxl: '9' };

	return (
		<Row classes={composeCSS({ row: Styles.vendorSearchRow })}>
			<Column {...columnSizes}>{isFetching ? <Loading /> : <View />}</Column>
			{isDesktop && (
				<Column lg="5" xl="4" xxl="3">
					<RightRail />
				</Column>
			)}
		</Row>
	);
};

const mapStateToProps = (state: Redux.State) => {
	const {
		search: { isFetching, maxResultColumns },
		viewport: { isMobile, isTablet },
	} = state;

	const isDesktop = !(isMobile || isTablet);

	return {
		isFetching,
		maxResultColumns,
		isDesktop,
	};
};

export default connect(mapStateToProps)(GridView);
