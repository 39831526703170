import { DisplayButton } from '@xo-union/tk-ui-links';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import * as SearchActions from '../../../../../../../redux/search/actions';
import Styles from './styles.scss';

interface StateProps {
	selectedFilterCount: number;
}

interface DispatchProps {
	clearSidebarFilters: () => Search.Actions.ClearSidebarFilters;
}

export type Props = StateProps & DispatchProps;

const MobileHeader: FunctionComponent<Props> = ({
	clearSidebarFilters,
	selectedFilterCount,
}) => (
	<div className={Styles.mobileHeaderContainer}>
		{selectedFilterCount > 0 && (
			<DisplayButton
				className={Styles.clearAllLink}
				onClick={clearSidebarFilters}
				type="button"
			>
				Clear All
			</DisplayButton>
		)}{' '}
		<h4>
			More Filters{selectedFilterCount > 0 ? ` (${selectedFilterCount})` : ''}
		</h4>
	</div>
);

const mapStateToProps = (state: Redux.State): StateProps => ({
	selectedFilterCount: state.search.filterPills.sidebar.selected.length,
});

const mapDispatchToProps: DispatchProps = {
	clearSidebarFilters: SearchActions.clearSidebarFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
