import { setAvailableSpace } from '@redux/search';
import { compose } from '@xo-union/react-css-modules';
import { Column, Row } from '@xo-union/tk-component-grid';
import { debounce } from 'lodash';
import React, { FC, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Search from '../../../../components/shared/Search';
import Title from './Title';
import Styles from './styles.scss';

type DispatchProps = typeof mapDispatchToProps;
interface OwnProps {
	inSemanticSearch?: boolean;
}
type ResultsHeaderProps = DispatchProps & OwnProps;

const ResultsHeader: FC<ResultsHeaderProps> = ({
	inSemanticSearch,
	setAvailableSize,
}) => {
	const headerRef = useRef(null);
	const debouncedSetSearch = useRef(debounce(setAvailableSize, 500)).current;
	const debouncedSetContainer = useRef(debounce(setAvailableSize, 500)).current;

	useEffect(() => {
		calculateWidth();
		if (window) {
			window.addEventListener('resize', calculateWidth);
			return () => {
				window.removeEventListener('resize', calculateWidth);
			};
		}

		return () => {
			// Do nothing
		};
	}, [headerRef.current]);

	const calculateWidth = () => {
		if (headerRef.current) {
			const style = window.getComputedStyle(headerRef.current);
			const width = style?.getPropertyValue('width');
			if (width) {
				const size = parseInt(width.replace('px', ''), 10);
				debouncedSetContainer(size, 'header');
			}
		}
	};

	const setSearchContainerSize = (size: number) => {
		debouncedSetSearch(size, 'search');
	};

	return (
		<Row
			classes={compose({
				row: `${Styles.container}`,
			})}
		>
			<Column xs="12" md="6" order="3" orderMd="1">
				<Title inSemanticSearch={inSemanticSearch} />
			</Column>
			{!inSemanticSearch && (
				<Column xs="12" md="6" order="2" orderMd="2">
					<Search setContainerSize={setSearchContainerSize} />
				</Column>
			)}
		</Row>
	);
};

const mapDispatchToProps = {
	setAvailableSize: setAvailableSpace,
};

export default connect(null, mapDispatchToProps)(ResultsHeader);
