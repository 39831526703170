import {
	DEFAULT_ALL_RADIO_OPTION_ID,
	filtersNamesWithAllOption,
} from '@redux/search';
import React, { VFC } from 'react';
import FilterPill from '../../components/FilterPill';
import SortPill from '../../components/SortPill';

export interface FilterPillCategoryProps {
	filterCategory: Search.FilterPillCategory;
}

const FilterPillCategory: VFC<FilterPillCategoryProps> = (props) => {
	const { filterCategory } = props;
	const defaultProps = filtersNamesWithAllOption.includes(filterCategory)
		? { defaultOptions: [DEFAULT_ALL_RADIO_OPTION_ID] }
		: {};

	if (filterCategory === 'Sort') {
		return <SortPill />;
	}

	return <FilterPill filterCategory={filterCategory} {...defaultProps} />;
};

export default FilterPillCategory;
