import { Body2, H4 } from '@xo-union/tk-ui-typography';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import Constants from '../../constants';
import {
	isRenameEstateVenuesFacetExperimentEnabled,
	seoTextVariantListingEstate,
} from '../../utils/renameEstateVenuesFacetExperiment';
import {
	isRenameGardenVenuesFacetExperimentEnabled,
	seoTextVariantListingGarden,
} from '../../utils/renameGardenVenuesFacetExperiment';
import {
	getSeoTextVariantListing,
	isRenameHistoricVenuesFacetExperimentEnabled,
} from '../../utils/renameHistoricVenuesFacetExperiment';
import TooltipInfo from './TooltipInfo';
import { locationToString } from './helpers';
import Styles from './styles.scss';

interface OwnProps {
	inSemanticSearch?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type TitleProps = OwnProps & StateProps;

const Title: FC<TitleProps> = (props) => {
	let {
		inSemanticSearch,
		location,
		totalVendors,
		categoryPlural,
		categorySingular,
		seoFilter,
	} = props;

	if (
		isRenameHistoricVenuesFacetExperimentEnabled(seoFilter, location.stateCode)
	) {
		seoFilter = getSeoTextVariantListing();
	} else if (
		isRenameGardenVenuesFacetExperimentEnabled(seoFilter, location.stateCode)
	) {
		seoFilter = seoTextVariantListingGarden;
	} else if (
		isRenameEstateVenuesFacetExperimentEnabled(seoFilter, location.stateCode)
	) {
		seoFilter = seoTextVariantListingEstate;
	}

	const cityState = locationToString(location);
	const categoryTerm = totalVendors === 1 ? categorySingular : categoryPlural;
	const headerText = !seoFilter || seoFilter === '' ? categoryTerm : seoFilter;
	const mobileText = `${totalVendors} ${headerText}`;
	const pageLocation = cityState;

	return (
		<div
			className={
				inSemanticSearch ? Styles.semanticSearchHeader : Styles.searchHeader
			}
		>
			<H4 className={Styles.mobile}>{mobileText}</H4>
			<H4 className={Styles.desktop} as="h1">
				{`${mobileText} in ${pageLocation}`}
			</H4>
			<div className={Styles.subText}>
				<Body2>{Constants.SUB_TEXT}</Body2>
				<TooltipInfo />
			</div>
		</div>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	location: state.location,
	categoryPlural: state.category.plural.term,
	categorySingular: state.category.singular.term,
	seoFilter: state.seo.seoFilter,
	totalVendors: state.search.total,
});

export default connect(mapStateToProps)(Title);
