import cx from 'classnames';
import Styles from './styles.scss';

const createClassName = (isSticky: boolean, showMapView: boolean) => {
	if (showMapView) {
		return cx(Styles.filterMapContainer, Styles.stickyContainer);
	}
	return cx(
		Styles.innerContainer,
		isSticky ? Styles.stickyContainer : undefined,
	);
};

export { createClassName };
