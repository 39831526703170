import { clearFiltersSearch } from '@api/mapView/clearFiltersSearch';
import Honeybadger from '@honeybadger-io/js';
import { useAppSelector } from '@redux/hooks';
import { useMap } from '@vis.gl/react-google-maps';
import { Dispatch, SetStateAction, useCallback } from 'react';

const useClearedSearch = (
	setFilteredVendors: Dispatch<SetStateAction<Vendor.Raw[]>>,
) => {
	const categoryGuid = useAppSelector((state) => state.category.id);
	const location = useAppSelector((state) => state.location);
	const page = useAppSelector((state) => state.search.pagination.page);
	const map = useMap();

	const handleClearedSearch = useCallback(async () => {
		const zeroIndexBasedPage = page > 0 ? page - 1 : 0;
		try {
			const searchResponse = await clearFiltersSearch({
				categoryGuid,
				filters: [categoryGuid],
				location,
				map,
				page: zeroIndexBasedPage,
			});
			if (searchResponse?.data?.search?.profiles) {
				setFilteredVendors(searchResponse.data.search.profiles);
			}
		} catch (error) {
			Honeybadger.notify(
				error,
				'useClearedSearch hook failed: Error searching with bounding box',
			);
		}
	}, [categoryGuid, location, map, page, setFilteredVendors]);

	return useCallback(() => {
		handleClearedSearch();
	}, [handleClearedSearch]);
};

export { useClearedSearch };
