import { Pagination } from '@xo-union/tk-ui-pagination';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Events, animateScroll } from 'react-scroll';
import { compose } from 'redux';
import {
	appendUrlParams,
	getCurrentPage,
	getTotalPageCount,
	isMarketBlockListed,
	syntheticPageLoad,
} from '../../utils';
import Styles from './styles.scss';

export class PaginationContainer extends Component {
	constructor(props) {
		super(props);

		this.handlePageChange = this.handlePageChange.bind(this);
	}

	componentWillUnmount = () => {
		Events.scrollEvent.remove('end');
	};

	handleRenderLink = ({ pageData: { page }, pageLinkProps }) => {
		// SEO does not want web crawlers to visit the last page of search results
		// We may have to revisit this because handlePageChange is not being called, so we will need an href for now. -SG
		const href = this.determineUrl(page);

		return (
			<a href={href} {...pageLinkProps} /> // eslint-disable-line
		);
	};

	handlePageChange({ page }, event) {
		if (event) {
			event.preventDefault();
		}
		const {
			history,
			location: { city, stateCode },
			marketCode,
			member,
			vendorCategoryCode,
		} = this.props;
		const url = this.determineUrl(page);
		const cityAndState = `${city}, ${stateCode}`;

		Events.scrollEvent.register('end', () => {
			history.push(url);
			syntheticPageLoad({
				city: cityAndState,
				marketCode,
				member,
				vendorCategoryCode,
			});
		});

		animateScroll.scrollToTop();
	}

	determineUrl(newPage) {
		const { pathname, search, marketCode } = this.props;
		const searchString =
			typeof window !== 'undefined' ? window.location.search : search;

		const filterParams = appendUrlParams(
			{ page: newPage },
			isMarketBlockListed(marketCode),
			searchString,
		);

		if (filterParams.length) {
			return `${pathname}?${filterParams}`;
		}

		return pathname;
	}

	render() {
		const { currentPage, totalPageCount } = this.props;

		return (
			totalPageCount > 1 && (
				<div className={Styles.container} data-testid="pagination-container">
					<Pagination
						activePage={currentPage}
						onPageChange={this.handlePageChange}
						renderLink={this.handleRenderLink}
						totalPageCount={totalPageCount}
					/>
				</div>
			)
		);
	}
}

PaginationContainer.propTypes = {
	currentPage: PropTypes.number.isRequired,
	filters: PropTypes.shape({
		categoryFilters: PropTypes.object.isRequired,
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		city: PropTypes.string.isRequired,
		stateCode: PropTypes.string.isRequired,
	}).isRequired,
	marketCode: PropTypes.string.isRequired,
	member: PropTypes.shape({
		id: PropTypes.string,
		legacy_user_id: PropTypes.string.isRequired,
	}),
	pathname: PropTypes.string.isRequired,
	search: PropTypes.string.isRequired,
	selection: PropTypes.string.isRequired,
	totalPageCount: PropTypes.number.isRequired,
	vendorCategoryCode: PropTypes.string.isRequired,
};

export function mapStateToProps(state, ownProps) {
	return {
		currentPage: getCurrentPage(state),
		filters: state.filters,
		location: state.location,
		marketCode: state.settings.marketCode,
		member: state.membership.member,
		pathname: ownProps.location.pathname,
		search: ownProps.location.search,
		selection: state.category.plural.term,
		totalPageCount: getTotalPageCount(state),
		vendorCategoryCode: state.category.code,
	};
}

const enhance = compose(withRouter, connect(mapStateToProps, null));

export default enhance(PaginationContainer);
