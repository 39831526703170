import {
	DEFAULT_ALL_RADIO_OPTION_ID,
	modifySelected as modifySelectedAction,
} from '@redux/search';
import {
	RadioButton,
	SingleSelectGroup,
} from '@xo-union/tk-component-switches';
import { Caption } from '@xo-union/tk-ui-typography';
import React, { useState, ChangeEvent, VFC } from 'react';
import { connect } from 'react-redux';
import { subtext } from '../../../utils';
import { OptionProps } from '../../types';
import Styles from '../SidebarOptions/styles.scss';

const SingleOption: VFC<OptionProps> = ({
	filter,
	modifySelected,
	selected,
}) => {
	const [allIds] = useState<string[]>(
		filter.filterOptions.map((option) => option.id),
	);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const exclude = selected.filter((item) =>
			allIds.every((id) => item !== id),
		);
		if (selected.includes(value)) {
			modifySelected(exclude);
		} else {
			modifySelected([...exclude, value]);
		}
	};

	return (
		<SingleSelectGroup>
			{filter.filterOptions.map((option) => {
				const captionText = subtext[option.id as keyof typeof subtext];
				const checked =
					selected.includes(option.id) ||
					(selected.length === 0 && option.id === DEFAULT_ALL_RADIO_OPTION_ID);
				return (
					<div className={Styles.option} key={`option_${option.id}`}>
						<RadioButton
							value={option.id}
							checked={checked}
							onChange={onChange}
						>
							{option.name}
							{captionText && (
								<Caption className={Styles.caption}>{captionText}</Caption>
							)}
						</RadioButton>
					</div>
				);
			})}
		</SingleSelectGroup>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	selected: state.search.filterPills.sidebar.selected,
});

export const mapDispatchToProps = {
	modifySelected: modifySelectedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleOption);
