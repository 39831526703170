import useTrackFilterVendors from '@hooks/useTrackFilterVendors';
import * as SearchActions from '@redux/search/actions';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import PillWithDropdown from '../../../../../../components/shared/PillWithDropdown';
import { labels, subtext } from '../../../utils';
import { buildCategoryFilters } from '../../helpers';
import { selectFilters } from '../../selectors';
import { prepareFilter } from '../../utils/helpers';

interface DispatchProps {
	addAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.AddAppliedFilterPillOptions;
	removeAppliedFilterPillOptions: (
		filters: Search.AppliedFilterPillProps[],
	) => Search.Actions.RemoveAppliedFilterPillOptions;
	resetStagedResultsCount: () => Search.Actions.ResetStagedResultsCount;
	commitAppliedFilterPillChanges: () => Search.Actions.CommitAppliedFilterPillChanges;
	fetchResultsCount: (filters: string[]) => void;
}

interface StateProps {
	categoryId: string;
	isMobile: boolean;
	savedFilters: Search.AppliedFilterPillProps[];
	filters: Search.FilterDetails[];
	vendorCategoryCode: Category.CategoryCode;
	categoryName: string;
	location: Redux.Location;
	stagedResultsCount: number;
}

export interface OwnProps {
	filterCategory: string;
	defaultOptions?: string[];
}

export type Props = DispatchProps & StateProps & OwnProps;

export const FilterPill: FunctionComponent<Props> = ({
	categoryId,
	categoryName,
	fetchResultsCount,
	filterCategory,
	filters,
	isMobile,
	location,
	savedFilters,
	addAppliedFilterPillOptions,
	removeAppliedFilterPillOptions,
	commitAppliedFilterPillChanges,
	vendorCategoryCode,
	resetStagedResultsCount,
	stagedResultsCount,
	defaultOptions,
}) => {
	const [trackVendors] = useTrackFilterVendors({
		categoryCode: vendorCategoryCode,
		categoryName,
		location,
	});
	const filter = prepareFilter(filters, filterCategory);
	const options =
		filter?.filterOptions.map((o) => ({
			name: o.name,
			slug: o.singular.slug,
			value: o.id,
		})) || [];

	const optionIds = options.map((o) => o.value);
	const appliedFiltersOutsideThisFilterPill = [
		...savedFilters.map((f) => f.id).filter((a) => !optionIds.includes(a)),
	];
	const appliedOptions = savedFilters
		.filter((f) => optionIds.includes(f.id))
		.map((f) => f.id);

	const onSave = (filtersToApply: string[]) => {
		const selectedFilterOptions: Search.AppliedFilterPillProps[] = [];
		for (const option of filtersToApply) {
			const filterOption = options.find((o) => o.value === option);
			if (!filterOption || !filter) {
				return;
			}
			const filterToAdd = {
				id: filterOption.value,
				name: filterOption.name,
				value: filterOption.slug,
				categorySlug: filter.slug,
			};

			if (!savedFilters.find((f) => f.id === filterToAdd.id)) {
				selectedFilterOptions.push(filterToAdd);
			}
		}

		addAppliedFilterPillOptions(selectedFilterOptions);

		const filtersToRemove = savedFilters.filter(
			(f) =>
				options.find((o) => o.name === f.name) &&
				!filtersToApply.includes(f.id),
		);
		removeAppliedFilterPillOptions(filtersToRemove);
		const stagedFilters = {
			add: selectedFilterOptions,
			remove: filtersToRemove,
		} as Search.StagedFilterPills;

		const appliedCategoryFilters = buildCategoryFilters(
			savedFilters,
			stagedFilters,
		);

		trackVendors(appliedCategoryFilters, 'save selected filters');

		commitAppliedFilterPillChanges();
	};

	const onClear = () => {
		fetchResultsCount([categoryId, ...appliedFiltersOutsideThisFilterPill]);
		const categoryFilters = buildCategoryFilters(savedFilters, {
			add: [],
			remove: [],
			commitChanges: false,
		});
		trackVendors(categoryFilters, 'clear selected filters');
	};

	const pillLabel =
		labels[filterCategory as keyof typeof labels] || filterCategory;

	const onSelectionChange = (selectedOptions: string[]) => {
		// We only need to fetch the result count on mobile
		if (!isMobile) {
			return;
		}
		const searchCountFilters = [
			categoryId,
			...appliedFiltersOutsideThisFilterPill,
			...selectedOptions,
		];
		fetchResultsCount(searchCountFilters);
	};

	const handleDropdownToggle = (isOpen: boolean) => {
		if (!isOpen) {
			resetStagedResultsCount();
		}
	};

	return (
		<PillWithDropdown
			isMobile={isMobile}
			onClearOptions={onClear}
			onDropdownToggle={handleDropdownToggle}
			onSave={onSave}
			onSelectionChange={onSelectionChange}
			pillLabel={pillLabel}
			appliedOptions={appliedOptions}
			options={options.map((o) => ({
				name: o.name,
				value: o.value,
				subtext: subtext[o.value as keyof typeof subtext],
			}))}
			resultsCount={stagedResultsCount}
			singleSelect={filter?.isSingleSelect}
			defaultOptions={defaultOptions}
		/>
	);
};

export const mapDispatchToProps: DispatchProps = {
	addAppliedFilterPillOptions: SearchActions.addAppliedFilterPillOptions,
	commitAppliedFilterPillChanges: SearchActions.commitAppliedFilterPillChanges,
	removeAppliedFilterPillOptions: SearchActions.removeAppliedFilterPillOptions,
	fetchResultsCount: SearchActions.fetchResultsCount,
	resetStagedResultsCount: SearchActions.resetStagedResultsCount,
};

export const mapStateToProps = (state: Redux.State): StateProps => ({
	categoryId: state.category.id,
	savedFilters: state.search.filterPills.applied,
	filters: selectFilters(state),
	vendorCategoryCode: state.category.code,
	categoryName: state.category.plural.term,
	location: state.location,
	isMobile: state.viewport.isMobile,
	stagedResultsCount: state.search.filterPills.stagedResultsCount,
});

export default connect<StateProps, DispatchProps, OwnProps>(
	mapStateToProps,
	mapDispatchToProps,
)(FilterPill);
