import { useTrackOpenFilterMenu } from '@hooks/use-track-open-filter-menu';
import Icon from '@xo-union/tk-component-icons';
import React, { FunctionComponent, useMemo } from 'react';
import { connect } from 'react-redux';
import { afterNextPaint } from '../../../../../../../helpers/after-next-paint';
import Pill from '../../../../../../components/shared/PillWithDropdown/Pill';
import { setIsSidebarOpen as setIsSidebarAction } from '../../../../../../redux/search';

import Styles from './styles.scss';

interface StateProps {
	appliedOptions: Search.AppliedFilterPillProps[];
	isSidebarOpen: boolean;
	mobileUI: boolean;
}

interface DispatchProps {
	setIsSidebarOpen: (bool: boolean) => void;
}

interface MapViewButtonProps {
	showMapView: boolean;
}

type Props = StateProps & DispatchProps & MapViewButtonProps;

const OnePillLabel = ({ children }: { children: React.ReactNode }) => (
	<div className={Styles.innerAlign}>
		<Icon name="filter" size="sm" />
		<span>{children}</span>
	</div>
);

const PillMore: FunctionComponent<Props> = ({
	appliedOptions,
	isSidebarOpen,
	mobileUI,
	setIsSidebarOpen,
	showMapView,
}) => {
	const trackOpenFilterMenu = useTrackOpenFilterMenu();

	const labelText = useMemo(() => {
		const labelPrefix = mobileUI ? 'Filters' : '+ More Filters';
		return `${labelPrefix}${
			appliedOptions.length > 0 ? ` (${appliedOptions.length})` : ''
		}`;
	}, [appliedOptions.length, mobileUI]);
	const label = useMemo(
		() => (mobileUI ? <OnePillLabel>{labelText}</OnePillLabel> : labelText),
		[mobileUI, labelText],
	);

	const handleClick = () => {
		afterNextPaint(() => {
			if (!isSidebarOpen) {
				trackOpenFilterMenu('more_filters');
			}
			setIsSidebarOpen(!isSidebarOpen);
		});
	};

	return (
		<Pill
			label={label}
			onClick={handleClick}
			selected={appliedOptions.length > 0 || isSidebarOpen}
			showMapView={showMapView}
		/>
	);
};

export const mapStateToProps = (state: Redux.State): StateProps => ({
	appliedOptions: state.search.filterPills.sidebar.applied,
	isSidebarOpen: state.search.filterPills.sidebar.isSidebarOpen,
	mobileUI: state.viewport.isMobile,
});

export const mapDispatchToProps: DispatchProps = {
	setIsSidebarOpen: setIsSidebarAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PillMore);
