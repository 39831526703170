import { Match } from '@hooks/use-search-by-bounding-box';
import { Column } from '@xo-union/tk-component-grid';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { MapViewButton } from '../../../MapView';
import PillMore from '../../components/PillMore';
import ClearFiltersLink from '../AppliedFilters/components/ClearFiltersLink';
import FilterPillCategory from './filterPillCategory';
import * as utils from './utils';

export type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	isSticky: boolean;
}

interface MapViewButtonProps {
	match: Match;
	searchLocation: string;
	showMapView: boolean;
	vendors: Vendor.Raw[];
}

type Props = StateProps & OwnProps & MapViewButtonProps;

export const FilterPills: FunctionComponent<Props> = (props) => {
	const {
		appliedFilters,
		filterCategories,
		isMobile,
		sidebarCategories,
		isSticky,
		match,
		searchLocation,
		showMapView,
		vendors,
	} = props;

	const pills = filterCategories.map((filterCategory) => (
		<FilterPillCategory key={filterCategory} filterCategory={filterCategory} />
	));

	return (
		<Column xs="12">
			<div
				className={utils.createClassName(isSticky, showMapView)}
				data-map-view={showMapView}
			>
				{!isMobile && <FilterPillCategory key="sort" filterCategory="Sort" />}
				{pills}
				{sidebarCategories.length > 0 && <PillMore showMapView={showMapView} />}
				{appliedFilters.length > 0 && isSticky && !isMobile && (
					<ClearFiltersLink />
				)}
				{showMapView && (
					<MapViewButton match={match} searchLocation={searchLocation} />
				)}
			</div>
		</Column>
	);
};

export const mapStateToProps = (state: Redux.State) => {
	return {
		appliedFilters: state.search.filterPills.applied,
		filterCategories: state.search.filterPills.categories,
		isMobile: state.viewport.isMobile,
		sidebarCategories: state.search.filterPills.sidebar.categories,
	};
};

export default connect<StateProps, OwnProps>(mapStateToProps)(FilterPills);
