import { useAppSelector } from '@redux/hooks';
import { useState } from 'react';
import { MAX_VENDORS } from '../constants';
import { validateCoordinates } from '../utils';

export const useFilteredVendors = () => {
	const vendors = useAppSelector((state) => state.search.vendors);
	const [filteredVendors, setFilteredVendors] = useState<Vendor.Raw[]>(() =>
		vendors
			.filter((vendor) =>
				validateCoordinates({
					lat: vendor.location.address.latitude,
					lng: vendor.location.address.longitude,
				}),
			)
			.slice(0, MAX_VENDORS),
	);

	const setFilteredVendorsWrapper = (vendors: Vendor.Raw[]) => {
		setFilteredVendors(vendors);
	};

	return { filteredVendors, setFilteredVendors, setFilteredVendorsWrapper };
};
