import { useCallback, useContext, useMemo } from 'react';
import Context from '../../../../../contexts/WeddingsContext/WeddingsContext';

export const useFavoriteButton = (vendor: Vendor.Raw) => {
	const { getIsSaved, onSaveHandler } = useContext(Context);
	const isFavorited = useMemo(
		() => getIsSaved(vendor.id),
		[getIsSaved, vendor.id],
	);

	const onClick = useCallback(() => {
		onSaveHandler(vendor, 'map_view');
	}, [onSaveHandler, vendor]);

	return {
		isFavorited,
		onClick,
	};
};
