import { modifySelected as modifySelectedAction } from '@redux/search';
import { Checkbox, MultiSelectGroup } from '@xo-union/tk-component-switches';
import { Caption } from '@xo-union/tk-ui-typography';
import React, { VFC, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { subtext } from '../../../utils';
import { OptionProps } from '../../types';
import Styles from '../SidebarOptions/styles.scss';

const MultiOption: VFC<OptionProps> = ({
	filter,
	modifySelected,
	selected,
}) => {
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (selected.includes(value)) {
			modifySelected(selected.filter((item) => item !== value));
		} else {
			modifySelected([...selected, value]);
		}
	};

	return (
		<MultiSelectGroup>
			{filter.filterOptions.map((option) => {
				const captionText = subtext[option.id as keyof typeof subtext];
				return (
					<div className={Styles.option} key={`option_${option.id}`}>
						<Checkbox
							value={option.id}
							checked={selected.includes(option.id)}
							onChange={onChange}
						>
							{option.name}
							{captionText && (
								<Caption className={Styles.caption}>{captionText}</Caption>
							)}
						</Checkbox>
					</div>
				);
			})}
		</MultiSelectGroup>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	selected: state.search.filterPills.sidebar.selected,
});

export const mapDispatchToProps = {
	modifySelected: modifySelectedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiOption);
