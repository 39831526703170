import colors from '@xo-union/tk-ui-colors/lib/variables.css';
import React from 'react';
import Styles from './map-pin.scss';

interface Props {
	vendorName: string;
	rating?: number;
	state?: 'visited' | 'selected' | 'default';
}

const colorMap = {
	default: {
		fill: colors.varNeutralWhite,
		stroke: colors.varNeutral300,
		text: colors.varNeutralBlack,
	},
	selected: {
		fill: colors.varNeutralBlack,
		stroke: colors.varNeutral600,
		text: colors.varNeutralWhite,
	},
	visited: {
		fill: colors.varNeutral200,
		stroke: colors.varNeutral400,
		text: colors.varNeutralBlack,
	},
};
export const MapPin = ({ rating, state = 'default', vendorName }: Props) => {
	const ratingDisplay = rating ? rating.toFixed(1) : '...';
	const { fill, stroke, text } = colorMap[state];
	return (
		<svg
			data-testid={`pin-${vendorName}-${state}`}
			role="img"
			width="46"
			height="37"
			viewBox="0 0 46 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>{vendorName}</title>
			<rect x="0.5" y="0.5" width="45" height="29" rx="14.5" fill={fill} />
			<rect x="0.5" y="0.5" width="45" height="29" rx="14.5" stroke={stroke} />
			<path
				d="M22.8921 35.3313L23.2666 35.7546L23.6411 35.3313L29.7744 28.398L30.5098 27.5667L29.3999 27.5667L17.1332 27.5667L16.0234 27.5667L16.7587 28.398L22.8921 35.3313Z"
				fill={fill}
				stroke={stroke}
			/>
			<path d="M14 27H32L30 29H16L14 27Z" fill={fill} />
			<text
				className={Styles.pinText}
				x="23"
				y="15"
				dominantBaseline="central"
				textAnchor="middle"
				fill={text}
			>
				{ratingDisplay}
			</text>
		</svg>
	);
};
