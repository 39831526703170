const MIN_LAT = 7;
const MAX_LAT = 83;
const MIN_LON = -168;
const MAX_LON = -15;

type LatLng = {
	lat: number;
	lng: number;
};

const isValidLatitude = (lat: number | undefined) => {
	return lat ? lat >= MIN_LAT && lat <= MAX_LAT : false;
};

const isValidLongitude = (lon: number | undefined) => {
	return lon ? lon >= MIN_LON && lon <= MAX_LON : false;
};

const setGoogleMapsBounds = (
	boundingBox: Search.BoundingBox | null,
): google.maps.LatLngBoundsLiteral => {
	const east = boundingBox?.bottomRight.longitude || 0;
	const north = boundingBox?.topLeft.latitude || 0;
	const south = boundingBox?.bottomRight.latitude || 0;
	const west = boundingBox?.topLeft.longitude || 0;

	return {
		east: isValidLongitude(east) ? east : MAX_LON,
		north: isValidLatitude(north) ? north : MAX_LAT,
		south: isValidLatitude(south) ? south : MIN_LAT,
		west: isValidLongitude(west) ? west : MIN_LON,
	};
};

const validateCoordinates = (coordinates: LatLng) => {
	return isValidLatitude(coordinates.lat) && isValidLongitude(coordinates.lng);
};

export { setGoogleMapsBounds, validateCoordinates };
