import type { MapCameraChangedEvent } from '@vis.gl/react-google-maps';
import { useCallback, useRef, useState } from 'react';
import { MIN_ZOOM } from '../GoogleMap';
import type { TrackMapView } from './use-track-map-view';
interface CreateTrackHandlerParams {
	hasSentAnalytics: React.MutableRefObject<boolean>;
	predicate: (e: MapCameraChangedEvent) => boolean;
	analyticsAction: () => void;
	handleZoom?: (e: MapCameraChangedEvent) => void;
}

export const useMapHandlers = (trackMapView: TrackMapView) => {
	const [mapIsReady, setMapisReady] = useState(false);
	const hasSentZoomInAnalytics = useRef(false);
	const hasSentZoomOutAnalytics = useRef(false);
	const hasSentDragEndAnalytics = useRef(false);
	const [currentZoom, setCurrentZoom] = useState(MIN_ZOOM);

	const createTrackHandler = useCallback(
		({
			hasSentAnalytics,
			predicate,
			analyticsAction,
			handleZoom,
		}: CreateTrackHandlerParams) =>
			(e: MapCameraChangedEvent) => {
				if (mapIsReady && handleZoom) {
					handleZoom(e);
				}
				if (mapIsReady && !hasSentAnalytics.current && predicate(e)) {
					hasSentAnalytics.current = true;
					analyticsAction?.();
				}
			},
		[mapIsReady],
	);

	const trackZoomIn = useCallback(
		(e: MapCameraChangedEvent) => {
			const trackEvent = createTrackHandler({
				hasSentAnalytics: hasSentZoomInAnalytics,
				predicate: (e) => e.detail.zoom > currentZoom,
				analyticsAction: () => {
					trackMapView('zoom-in');
				},
				handleZoom: (e) => {
					setCurrentZoom(e.detail.zoom);
				},
			});

			return trackEvent(e);
		},
		[createTrackHandler, currentZoom, trackMapView],
	);

	const trackZoomOut = useCallback(
		(e: MapCameraChangedEvent) => {
			const trackEvent = createTrackHandler({
				hasSentAnalytics: hasSentZoomOutAnalytics,
				predicate: (e) => e.detail.zoom < currentZoom,
				analyticsAction: () => {
					trackMapView('zoom-out');
				},
				handleZoom: (e) => {
					setCurrentZoom(e.detail.zoom);
				},
			});

			return trackEvent(e);
		},
		[createTrackHandler, currentZoom, trackMapView],
	);

	const trackDrag = useCallback(
		(e: MapCameraChangedEvent) => {
			const trackEvent = createTrackHandler({
				hasSentAnalytics: hasSentDragEndAnalytics,
				predicate: () => true,
				analyticsAction: () => {
					trackMapView('drag-end');
				},
			});

			return trackEvent(e);
		},
		[createTrackHandler, trackMapView],
	);

	const trackZoom = useCallback(
		(e: MapCameraChangedEvent) => {
			trackZoomIn(e);
			trackZoomOut(e);
		},
		[trackZoomIn, trackZoomOut],
	);

	const onTilesLoaded = useCallback(() => {
		setMapisReady(true);
	}, []);

	return { trackZoom, trackDrag, onTilesLoaded };
};
