import { getTextWidth } from '../../../../../../utils/truncate';

const font = '12px union-primary-font';
const getClearFiltersWidth = (): number => {
	return getTextWidth('Clear All', font);
};

export const calculateAppliedFiltersSize = (
	appliedFilters: Search.FilterPills['applied'],
	isLastFilterIndex: boolean,
	isMobile: boolean,
	rowNumber: number,
) => {
	const clearFiltersSize =
		appliedFilters.length === 0 || !isLastFilterIndex
			? 0
			: getClearFiltersWidth();
	const truncationPillSize =
		isMobile && !isLastFilterIndex && rowNumber === 1
			? getTextWidth('+10 more', font) + 32
			: 0;
	const sizes = appliedFilters.map(
		(filter) => getTextWidth(filter.name, font) + 54,
	);
	return sizes.reduce(
		(acc, cur) => acc + cur,
		clearFiltersSize + truncationPillSize,
	);
};

// Extracting to make it easier to mock
export const windowWidth = (): number => window.innerWidth;
