import Item from '@components/vendorCard/components/SecondaryInfo/item';
import { BOW_FACET_ID } from '@components/vendorCard/constants';
import secondaryInfo from '@components/vendorCard/public/extractVendorProps/extract/secondaryInfo';
import buildFacetIds from '@components/vendorResult/buildFacetIds';
import { mobileMapViewAssignmentSelector } from '@redux/experiments/selectors/mobile-map-view';
import { useAppSelector } from '@redux/hooks';
import BlankButton from '@xo-union/component-blank-button';
import Icon from '@xo-union/tk-component-icons';
import { Img, Picture } from '@xo-union/tk-component-picture';
import { VendorDefaultImage } from '@xo-union/tk-component-vendor-card';
import { Body2, Caption } from '@xo-union/tk-ui-typography';
import React, { FC, useMemo } from 'react';
import { useFavoriteButton } from '../hooks/use-favorite-button';
import type { TrackMapView } from '../hooks/use-track-map-view';
import Styles from './mini-vendor-card.scss';

interface ReviewContentProps {
	count: number;
	rating: number;
}

const ReviewContent: FC<ReviewContentProps> = ({ count, rating }) => {
	const hasReviews = count > 0;
	return (
		<>
			<Icon
				className={Styles.star}
				aria-hidden="true"
				name={hasReviews ? 'star_filled' : 'star'}
				size="sm"
			/>
			<Caption bold className={Styles.rating}>
				{`${rating}`}
			</Caption>
			<Caption className={Styles.caption}>{`(${count})`}</Caption>
		</>
	);
};

interface SecondaryInfoProps {
	secondaryInfo: string[];
}

const SecondaryInfo: FC<SecondaryInfoProps> = (props) => {
	const { secondaryInfo } = props;

	const filteredInfo = useMemo(() => {
		if (!secondaryInfo || Object.keys(secondaryInfo).length === 0) {
			return null;
		}

		return secondaryInfo.filter((info) => !info.match(/Outdoor Event Space/i));
	}, [secondaryInfo]);

	if (!filteredInfo) {
		return null;
	}

	return (
		<div
			className={Styles.secondaryInfoContainer}
			data-testid="secondary-info-container"
		>
			{filteredInfo.map((info, index) => (
				<Item key={info} text={info} isFirst={index === 0} />
			))}
		</div>
	);
};

interface FavoriteButtonProps {
	vendor: Vendor.Raw;
}
const FavoriteButton = (props: FavoriteButtonProps) => {
	const { vendor } = props;
	const { isFavorited, onClick } = useFavoriteButton(vendor);

	return (
		<BlankButton
			className={Styles.favoriteButton}
			onClick={onClick}
			aria-label="Save"
			aria-pressed={isFavorited ? 'true' : 'false'}
		>
			<Icon
				className={isFavorited ? Styles.heartFilled : undefined}
				name={isFavorited ? 'heart_filled' : 'heart'}
				size="sm"
				aria-hidden="true"
			/>
		</BlankButton>
	);
};

const extractMiniCardDetails = (vendor: Vendor.Raw) => {
	const facetIds = buildFacetIds(vendor);
	const vendorInfo = secondaryInfo(vendor, facetIds);
	return {
		imgSrc: vendor.storefrontCard?.url
			? `${vendor.storefrontCard.url}~sc_194.194`
			: '',
		isBow:
			vendor.affiliates.find((affiliate) => affiliate.id === BOW_FACET_ID) ||
			false,
		reviewCount: vendor.reviewSummary?.count || 0,
		reviewRating: vendor.reviewSummary?.overallRating || 0,
		secondaryInfo: vendorInfo,
	};
};

export interface MiniVendorCardProps {
	trackMapView: TrackMapView;
	vendor: Vendor.Raw;
}

export const MiniVendorCard: FC<MiniVendorCardProps> = (props) => {
	const { trackMapView, vendor } = props;
	const isMapView =
		useAppSelector((state) => mobileMapViewAssignmentSelector(state)) ===
		'map-toolbar';
	const vendorUrl = vendor?.siteUrls[0]?.relativeUri || '/';
	const { imgSrc, isBow, reviewCount, reviewRating, secondaryInfo } =
		extractMiniCardDetails(vendor);

	return (
		<div className={Styles.vendorCard} data-map-view={isMapView}>
			{/* biome-ignore lint/a11y/useValidAnchor: https://biomejs.dev/linter/rules/use-valid-anchor/#valid */}
			<a
				onClick={() => trackMapView('vendor-card-click', vendor)}
				href={vendorUrl}
				style={{ display: 'flex' }}
				className={Styles.link}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className={Styles.imageContainer}>
					<div className={Styles.defaultImageContainer}>
						{imgSrc ? (
							<Picture>
								<Img
									className={Styles.image}
									alt="Vendor Preview Thumbnail"
									src={imgSrc}
								/>
							</Picture>
						) : (
							<div className={Styles.noMediaContainer}>
								<VendorDefaultImage />
							</div>
						)}
					</div>
				</div>

				<div className={Styles.contentContainer}>
					<Body2 className={Styles.vendorName}>{vendor.name}</Body2>

					<div className={Styles.reviewsAndAwards}>
						<div className={Styles.reviewsContainer}>
							<ReviewContent count={reviewCount} rating={reviewRating} />
						</div>
						{isBow && (
							<div className={Styles.awardsContainer}>
								<Caption className={Styles.award} size="sm" bold>
									Best of Weddings
								</Caption>
							</div>
						)}
					</div>
					<div className={Styles.vendorInfoContainer}>
						{secondaryInfo.length > 0 && (
							<SecondaryInfo secondaryInfo={secondaryInfo} />
						)}
					</div>
				</div>
			</a>
			<div className={Styles.favContainer}>
				<FavoriteButton vendor={vendor} />
			</div>
		</div>
	);
};
