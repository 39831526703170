import React, { useState, useEffect, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import {
	modifyApplied as modifyAppliedAction,
	modifySelected as modifySelectAction,
	setClearSidebarFlag as setClearSidebarFlagAction,
} from '../../../../../../redux/search/actions';
import Sidebar from '../../components/Sidebar';
import { findCategoryData, lookUpAndTransform } from '../../utils/helpers';

interface StateProps {
	appliedFilters: Search.AppliedFilterPillProps[];
	categories: string[];
	clearSidebarFlag: string;
	filters: Search.FilterDetails[];
	selected: string[];
}

interface DispatchProps {
	modifyApplied: (
		applied: Search.AppliedFilterPillProps[],
	) => Search.Actions.ModifyApplied;
	modifySelected: (selected: string[]) => Search.Actions.ModifySelected;
	setClearSidebarFlag: (flag: string) => Search.Actions.SetClearSidebarFlag;
}

export type Props = StateProps & DispatchProps;

const FilterSidebar: FunctionComponent<Props> = ({
	appliedFilters,
	categories,
	clearSidebarFlag,
	filters,
	modifyApplied,
	modifySelected,
	selected,
	setClearSidebarFlag,
}) => {
	const [categoryFilters, setCategoryFilters] = useState<
		Search.FilterDetails[]
	>([]);
	const [flattenSidebarOptions, setFlattenSidebarOptions] = useState<string[]>(
		[],
	);

	useEffect(() => {
		// fill out data for sidebar
		setCategoryFilters(findCategoryData(categories, filters));
	}, [categories]);

	useEffect(() => {
		if (appliedFilters.length > 0) {
			const flatten: string[] = [];
			categoryFilters.forEach((filter) => {
				filter.filterOptions.forEach((option) => {
					flatten.push(option.id);
				});
			});
			setFlattenSidebarOptions(flatten);
		}
	}, [appliedFilters]);

	useEffect(() => {
		const flattenSidebarByName: string[] = [];
		categoryFilters.forEach((filter) => {
			filter.filterOptions.forEach((option) => {
				flattenSidebarByName.push(option.name);
			});
		});

		if (clearSidebarFlag === 'ALL') {
			modifyApplied([]);
			modifySelected([]);
			setClearSidebarFlag('');
		} else if (clearSidebarFlag !== '') {
			const removedOption = appliedFilters.filter((filter) => {
				return filter.name === clearSidebarFlag;
			});
			const optionToKeep = selected.filter((filter) => {
				return removedOption.every((option) => option.id !== filter);
			});
			modifyApplied(
				optionToKeep.map((option) =>
					lookUpAndTransform(option, 'id', categoryFilters),
				),
			);
			modifySelected(optionToKeep);
			setClearSidebarFlag('');
		}
	}, [clearSidebarFlag]);

	return (
		<Sidebar
			categoryFilters={categoryFilters}
			flattenSidebarOptions={flattenSidebarOptions}
		/>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	appliedFilters: state.search.filterPills.sidebar.applied,
	categories: state.search.filterPills.sidebar.categories,
	clearSidebarFlag: state.search.filterPills.sidebar.clearSidebarFlag,
	filters: state.search.filters.filters,
	selected: state.search.filterPills.sidebar.selected,
});

export const mapDispatchToProps = {
	modifyApplied: modifyAppliedAction,
	modifySelected: modifySelectAction,
	setClearSidebarFlag: setClearSidebarFlagAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterSidebar);
