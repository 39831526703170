import Icon from '@xo-union/tk-component-icons';
import {
	Tooltip as UnionTooltip,
	TooltipContainer,
} from '@xo-union/tk-component-tooltips';
import React, { forwardRef } from 'react';
import Constants from '../../../../constants';
import Styles from '../../styles.scss';
import type { BaseTooltipProps } from '../types';

interface TooltipProps extends BaseTooltipProps {
	isDesktop: boolean;
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
	const { isDesktop, setTooltipOpen, tooltipOpen } = props;
	const toggleOpenTooltip = () => setTooltipOpen(!tooltipOpen);

	const arrowPosition = isDesktop ? 'left' : 'top';
	const arrowOffset = isDesktop ? 50 : 95;

	return (
		<TooltipContainer>
			<div
				className={Styles.tooltipTarget}
				onClick={toggleOpenTooltip}
				onKeyPress={toggleOpenTooltip}
				ref={ref}
				role="button"
				tabIndex={0}
			>
				<Icon name="info" size="sm" />
			</div>
			{tooltipOpen && (
				<UnionTooltip
					arrowPosition={arrowPosition}
					arrowOffset={arrowOffset}
					className={Styles.tip}
					role="alert"
				>
					{Constants.TOOLTIP_TEXT}
				</UnionTooltip>
			)}
		</TooltipContainer>
	);
});

export default Tooltip;
