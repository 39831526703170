import { IconButton } from '@xo-union/tk-component-icons';
import React, { Fragment, ReactElement } from 'react';
import AppliedFilter from '../../../../../../../../components/shared/AppliedFilter';
import ClearFiltersLink from '../../components/ClearFiltersLink';
import Styles from './styles.scss';

type AppliedFilterRow = Search.AppliedFilterPillProps[];

export interface Props {
	closeFilter: (filterLabel: string) => void;
	index: number;
	isLastRow: boolean;
	numberOfHiddenFilters: number;
	row: AppliedFilterRow;
	setExpandFilters: (expandFilters: boolean) => void;
}

const AppliedFiltersRow = (props: Props): ReactElement<Props> => {
	const {
		closeFilter,
		isLastRow,
		numberOfHiddenFilters,
		row,
		setExpandFilters,
	} = props;
	const enableExpandedFilters = () => setExpandFilters(true);
	return (
		<Fragment>
			{row.map((filter) => {
				const onClick = () => closeFilter(filter.name);
				return (
					<AppliedFilter key={filter.name} label={filter.name}>
						<IconButton
							data-testid={`${filter.name} button`}
							className={Styles.deleteFilterButton}
							name="close"
							onClick={onClick}
							size="sm"
						/>
					</AppliedFilter>
				);
			})}
			{isLastRow && numberOfHiddenFilters > 0 && (
				<AppliedFilter
					onClick={enableExpandedFilters}
					label={`+${numberOfHiddenFilters} more`}
				/>
			)}
			{isLastRow && <ClearFiltersLink />}
		</Fragment>
	);
};

export default AppliedFiltersRow;
